import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'

import { Footer as ChaliceFooter } from '@chalicenetwork/chalice-style'

import style from './footer.module.scss'

const launchIntercom = e => {
  if (typeof Intercom !== 'undefined') {
    window.Intercom('show')
  }
}

const Footer = props => (
  <StaticQuery
    query={graphql`
      query LogoReversed {
        contentfulAsset(title: {eq: "logo-reversed"}) {
          fluid(maxWidth: 500, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    `}
    render={ data => (
      <ChaliceFooter>
        <div className={style.grid}>
          <div className={style.gridItem}>
            <Img alt="Chalice Pay" fluid={data.contentfulAsset.fluid} style={{ width: '300px' }} />
          </div>
          <div className={classNames(style.gridItem, style.menu)}>
            <button
              onClick={() => launchIntercom()}
              className={style.menuLink}>Contact us</button>
            <div className={style.menuItem}>|</div>
            <a className={style.menuLink} href="#pricing">Pricing</a>
            <div className={style.menuItem}>|</div>
            <Link className={style.menuLink} to="/tos">Terms</Link>
            <div className={style.menuItem}>|</div>
            <Link className={style.menuLink} to="/pp">Privacy</Link>
          </div>
        </div>
      </ChaliceFooter>
    )} />
)

export default Footer
