import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { slide as Menu } from 'react-burger-menu'

import { CommonPose } from '../components/animations'

const Header = props => (
  <StaticQuery
    query={graphql`
      query Logo {
        logo: contentfulAsset(title: {eq: "chalicepay-logo"}) {
          fluid(maxWidth: 500, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        power: contentfulAsset(title: {eq: "powered-by-chalice"}) {
          fluid(maxWidth: 100, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    `}
    render={ data => {
      const [ open, setOpen ] = useState(false)
      return (
        <>
          <div className="mobile-menu">
            <Menu
              width={'100%'}
              right isOpen={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}>
              <div className="menu-top">
                <a className="menu-item menu-link" href={process.env.SIGNUP_URL}>SIGN UP</a>
                <a className="menu-item menu-link" href="#pricing">PRICING</a>
              </div>
              <div className="menu-bottom">
                <CommonPose>
                  <a className="powered-by" href="https://www.chalicenetwork.com">
                    <Img loading="eager" alt="Powerd by Chalice" fluid={data.power.fluid} style={{ width: '300px' }} />
                  </a>
                </CommonPose>
              </div>
            </Menu>
          </div>
          <div className="header">
            <div className="container">
              <div className="header-wrapper">
                <div className="align-center">
                  <a className="logo" href="/">
                    <Img loading="eager" alt="Chalice Pay" fluid={data.logo.fluid} style={{ width: '300px' }} />
                  </a>
                </div>
                <div className="menu">
                  <CommonPose className="align-center">
                    <a className="menu-item menu-link" href={process.env.SIGNUP_URL}>SIGN UP</a>
                  </CommonPose>
                  <CommonPose className="align-center">
                    <div className="menu-item">|</div>
                  </CommonPose>
                  <CommonPose className="align-center">
                    <a className="menu-item menu-link" href="#pricing">PRICING</a>
                  </CommonPose>
                  <CommonPose>
                    <a className="powered-by" href="https://www.chalicenetwork.com">
                      <Img loading="eager" alt="Powerd by Chalice" fluid={data.power.fluid} style={{ width: '300px' }} />
                    </a>
                  </CommonPose>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }} />
)

export default Header
