import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import VisibilitySensor from 'react-visibility-sensor'

const inPrerender = () => {
  return typeof window !== 'undefined'
}

const variants = {
  show: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.05
    }
  },
  hide: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1
    }
  }
}

class VisbilityContainer extends Component {
  static propTypes = {
    children: PropTypes.any,
    lazyLoad: PropTypes.bool
  }

  state = {
    animate: 'hide'
  }

  show = isVisible => {
    if (isVisible) {
      this.setState({
        animate: 'show'
      })
    }
  }

  renderChildren = () => {
    if (this.props.lazyLoad) {
      return this.state.animate === 'show'
        ? this.props.children
        : (<div style={{ height: '100px' }}></div>)
    } else {
      return this.props.children
    }
  }

  render () {
    return (
      <VisibilitySensor
        onChange={this.show}
        partialVisibility={true}
        active={inPrerender()}>
        <motion.div
          initial="hide"
          animate={this.state.animate}
          variants={variants}>
          { this.renderChildren() }
        </motion.div>
      </VisibilitySensor>
    )
  }
}

const HeaderPose = props => (
  <motion.h1 variants={{
    show: { y: 0, opacity: 1, duration: 50 },
    hide: { y: 0, opacity: 0, duration: 50 }
  }} {...props}>
    { props.children }
  </motion.h1>
)

const CommonPose = props => (
  <motion.div variants={{
    show: { y: 0, opacity: 1, duration: 50 },
    hide: { y: 0, opacity: 0, duration: 50 }
  }} {...props}>
    { props.children }
  </motion.div>
)

export {
  VisbilityContainer,
  CommonPose,
  HeaderPose
}
